import React from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import { setNodeStyle, updateUserSettings } from '../../store/userSlice'
import ColorPicker from './ColorPicker'
import { useDispatch } from "react-redux";


export default function DisplaySettings() {
    const dispatch = useDispatch();
    const userSettings = useSelector((state: RootState) => state.user.userSettings)
    function updateSettings(){
        dispatch(updateUserSettings(userSettings))
    }
    function onChangeNodeShape(value){
        dispatch(setNodeStyle(value.target.value))
    }

    return (<Container>
        <Row>
            <Col xs="4">
                <span>Node Color: </span>
            </Col>
            <Col>
                <ColorPicker color={userSettings.nodeColor} />
            </Col>
        </Row>
        <Row>
            <Col xs="4">
                <span>Node Style: </span>
            </Col>
            <Col xs="3">
                <Form.Select onChange={onChangeNodeShape} value={userSettings.nodeShape} size="sm">
                    <option value="circle">Circle</option>
                    <option value="box">Box</option>
                </Form.Select>
            </Col>
        </Row>
        <Row>
            <Col xs="4">
                <span>Graph/Schedule Layout: </span>
            </Col>
            <Col xs="3">
                <Form.Select size="sm" disabled value='vertical'>
                    <option value="horizontal">Horizontal</option>
                    <option value="vertical">Vertical</option>
                </Form.Select>
            </Col>
        </Row>
        <Row>
            <Col xs="3">
                <Button onClick={() => updateSettings()} variant="dagable" size="sm">
                    Save settings
                </Button>
            </Col>
        </Row>
    </Container>)
}
