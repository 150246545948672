import React, { useState } from "react";
import {
  postCriticalPathGraph,
  postDefaultGraph,
  rescheduleGraph,
  saveGraph,
} from "../../store/graphSlice";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import RangeSlider from "react-bootstrap-range-slider";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { selectGraphNode } from "../../store/graphInteractionSlice";
import { signinRedirect } from "../../services/userService";
import DagableButton from "../layout/dagableButton";

function DagableGraphSettings() {
  const user = useSelector((state: RootState) => state.auth.user);
  const dag = useSelector((state: RootState) => state.graph.schedule);
  const graph = useSelector((state: RootState) => state.graph.graphObject);
  const dagStatus = useSelector((state: RootState) => state.graph.status);

  const dispatch = useDispatch();
  const [nodeValue, setNodeValue] = useState(10);
  const [layerValue, setLayerValue] = useState(5);
  const [percentage, setPercentageValue] = useState(50);
  const [cpathGraph, setcpathGraph] = useState(1);
  const [maxComp, setMaxComp] = useState(30);
  const [minComp, setMinComp] = useState(1);
  const [maxComm, setMaxComm] = useState(30);
  const [minComm, setMinComm] = useState(1);
  const [numberOfProcessors, setNoProcessors] = useState(1);
  const [graphName, setGraphName] = useState("");
  const [graphDescription, setGraphDescription] = useState("");

  function updateGraph() {
    dispatch(selectGraphNode(undefined));
    if (cpathGraph) {
      dispatch(
        postCriticalPathGraph({
          nodes: nodeValue,
          layers: layerValue,
          percentage: percentage,
          maxComm: maxComm,
          minComm: minComm,
          maxComp: maxComp,
          minComp: minComp,
          processors: numberOfProcessors,
        })
      );
      return;
    }
    dispatch(
      postDefaultGraph({
        nodes: nodeValue,
        layers: layerValue,
        percentage: percentage,
      })
    );
  }

  function postRescheduleGraph() {
    dispatch(
      rescheduleGraph({
        Processors: numberOfProcessors,
        TaskGraph: graph,
      })
    );
  }

  function postSaveGraph() {
    dispatch(
      saveGraph({
        TaskGraph: graph,
        Name: graphName,
        Description: graphDescription,
      })
    );
  }

  function hideShowExtraOptions() {
    return cpathGraph ? "fade-in" : "d-none";
  }

  function renderLoginMessage() {
    return "";
    return !user && cpathGraph ? (
      <small className="text-muted">
        &nbsp;You must be{" "}
        <Button
          variant="link"
          rel="noreferrer"
          onClick={() => login()}
          className="p-0 pb-1"
        >
          signed in.
        </Button>
      </small>
    ) : (
      ""
    );
  }

  function login() {
    signinRedirect();
  }

  function renderGenerateButton() {
    return (
      <Col sm="6">
        <DagableButton
          className="mt-2"
          onClick={() => updateGraph()}
          //disabled={!user && cpathGraph ? true : false}
          value="Generate"
          isLoading={dagStatus === "loading"}
        ></DagableButton>
        {renderLoginMessage()}
      </Col>
    );
  }

  function renderRescheduleButton() {
    let isScheduledRendered =
      dag.nodeProcessorMappings !== undefined &&
      Object.keys(dag.nodeProcessorMappings).length > 0;
    if (!cpathGraph || !isScheduledRendered) {
      return;
    }
    return (
      <Col sm={6}>
        <DagableButton
          className="mt-2"
          onClick={() => postRescheduleGraph()}
          value="Reschedule"
          isLoading={dagStatus === "loading"}
        ></DagableButton>
      </Col>
    );
  }

  function renderSaveSection() {
    const isScheduledRendered =
      dag.nodeProcessorMappings !== undefined &&
      Object.keys(dag.nodeProcessorMappings).length > 0;
    if (!cpathGraph || !isScheduledRendered || !user) {
      return;
    }
    return (
      <Row>
        <Form.Group controlId="exampleForm.ControlInput1">
          <Form.Label column="sm" lg={12}>
            Graph Name
          </Form.Label>
          <Form.Control
            size="sm"
            type="email"
            placeholder="Example Graph Name"
            onChange={(changeEvent) => setGraphName(changeEvent.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="exampleForm.ControlTextarea1">
          <Form.Label column="sm" lg={12}>
            Graph Description
          </Form.Label>
          <Form.Control
            size="sm"
            as="textarea"
            rows={2}
            onChange={(changeEvent) =>
              setGraphDescription(changeEvent.target.value)
            }
          />
        </Form.Group>
        <Col>
          <DagableButton
            className="my-2"
            onClick={() => postSaveGraph()}
            value="Save"
            isLoading={dagStatus === "loading"}
          ></DagableButton>
        </Col>
      </Row>
    );
  }

  return (
    <Form>
      <Row>
        <Col sm={6}>
          <Form.Group controlId="formNodeCount">
            <Form.Label column="sm" lg={12}>
              Node Count
            </Form.Label>
            <RangeSlider
              value={nodeValue}
              tooltipPlacement='top'
              onChange={(changeEvent) =>
                setNodeValue(Number(changeEvent.target.value))
              }
              variant="primary"
              min={10}
              max={1000}
            />
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group controlId="formLayerCount">
            <Form.Label column="sm" lg={12}>
              Layer Count
            </Form.Label>
            <RangeSlider
              value={layerValue}
              tooltipPlacement='top'
              onChange={(changeEvent) =>
                setLayerValue(Number(changeEvent.target.value))
              }
              variant="primary"
              min={5}
              max={200}
            />
          </Form.Group>
        </Col>
      </Row>
      <Form.Group controlId="formPercentageCount">
        <Form.Label column="sm" lg={12}>
          Node Connection Percentage
        </Form.Label>
        <RangeSlider
          value={percentage}
          tooltipPlacement='top'
          onChange={(changeEvent) =>
            setPercentageValue(Number(changeEvent.target.value))
          }
          tooltipLabel={(currentValue) => `${currentValue}%`}
          variant="primary"
          min={0}
          max={cpathGraph ? 100 : 100}
          step={5}
        />
      </Form.Group>
      <Form.Group>
        <Form.Check
          value={cpathGraph}
          onChange={(changeEvent) => {
            setcpathGraph(Number(changeEvent.target.checked));
            setPercentageValue(5);
          }}
          type="switch"
          id="custom-switch"
          label="Generate critical path?"
        />
      </Form.Group>
      <div className={hideShowExtraOptions()}>
        <Row>
          <Col sm={6}>
            <Form.Group controlId="formMinComp">
              <Form.Label column="sm" lg={12}>
                Node Min Computation Time
              </Form.Label>
              <RangeSlider
                value={minComp}
                tooltipPlacement='top'
                onChange={(changeEvent) =>
                  setMinComp(Number(changeEvent.target.value))
                }
                variant="primary"
                min={1}
                max={20}
                step={1}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group controlId="formMaxComp">
              <Form.Label column="sm" lg={12}>
                Node Max Computation Time
              </Form.Label>
              <RangeSlider
                value={maxComp}
                tooltipPlacement='top'
                onChange={(changeEvent) =>
                  setMaxComp(Number(changeEvent.target.value))
                }
                variant="primary"
                min={30}
                max={50}
                step={1}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group controlId="formMinComm">
              <Form.Label column="sm" lg={12}>
                Edge Min Communication Time
              </Form.Label>
              <RangeSlider
                value={minComm}
                tooltipPlacement='top'
                onChange={(changeEvent) =>
                  setMinComm(Number(changeEvent.target.value))
                }
                variant="primary"
                min={1}
                max={20}
                step={1}
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group controlId="forMaxComm">
              <Form.Label column="sm" lg={12}>
                Edge Max Communication Time
              </Form.Label>
              <RangeSlider
                value={maxComm}
                tooltipPlacement='top'
                onChange={(changeEvent) =>
                  setMaxComm(Number(changeEvent.target.value))
                }
                variant="primary"
                min={20}
                max={40}
                step={1}
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group controlId="formNoProcessors">
          <Form.Label column="sm" lg={12}>
            Number of processors
          </Form.Label>
          <RangeSlider
            value={numberOfProcessors}
            tooltipPlacement='top'
            onChange={(changeEvent) =>
              setNoProcessors(Number(changeEvent.target.value))
            }
            variant="primary"
            min={1}
            max={4}
            step={1}
          />
        </Form.Group>
      </div>
      <Row>
        {renderGenerateButton()}
        {renderRescheduleButton()}
      </Row>
      {renderSaveSection()}
    </Form>
  );
}

export default DagableGraphSettings;
