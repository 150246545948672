import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface GraphInteractionState { 
    selectedNodeId: number | undefined
}

const initialState = {
    selectedNodeId: undefined
} as GraphInteractionState


const graphInteractionSlice = createSlice({
    name: "graphInteraction",
    initialState: initialState,
    reducers: {
        selectGraphNode: (state, action: PayloadAction<number | undefined>) => {
            state.selectedNodeId = action.payload
        }
    }
})

export const { selectGraphNode } = graphInteractionSlice.actions
export default graphInteractionSlice.reducer
