import React from "react";
import { Container, Spinner } from "react-bootstrap";

export default function FullPanelLoader(props: any) {
  return (
    <Container fluid className="homepage-container">
      <Spinner animation="border" role="status" variant="dagable">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <div
        className="d-flex align-items-center justify-content-center ps-5 pt-5"
        style={{ height: "100%" }}
      >
        {props.message}
      </div>
    </Container>
  );
}
