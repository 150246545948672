export const GRAPH_OPTIONS = {
    layout: {
        improvedLayout: true,
        hierarchical: {
            enabled: true,
            sortMethod: "directed",
            levelSeparation: -200,
            direction: "UD",
        },
    },
    physics:{
        enabled: false
    },
    edges: {
        arrows: {
            to: { enabled: true, scaleFactor: 1, type: "arrow" },
            middle: { enabled: false, scaleFactor: 1, type: "arrow" },
            from: { enabled: false, scaleFactor: 1, type: "arrow" },
        },
        color: "#000000",
    },

    nodes: {
        shape: "circle",
        margin: 10,
        color: '#f16f4e',
        font: {
            color: 'white'
        }
    },
    height: "100%",
    width: "100%"
};