import React from "react";
import { Button, Spinner } from "react-bootstrap";

export default function DagableButton(props) {
  return (
    <>
      {!props.isLoading && (
        <Button
          type="button"
          className={`${props.className} btn-block col-12`}
          variant="dagable"
          onClick={() => props.onClick()}
          disabled={props.disabled}
        >
          {props.value}
        </Button>
      )}
      {props.isLoading && (
        <Button
          disabled
          type="button"
          className={`${props.className} btn-block col-12`}
          variant="dagable"
          onClick={() => props.onClick()}
        >
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          {'   '}
        </Button>
      )}
    </>
  );
}
