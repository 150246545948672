import {  createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CriticalGraphDTO, StandardGraphDTO } from "../models/DTO/PostGraphRequest";
import { DagableEdge } from "../models/Edge";
import { DagableNode } from "../models/Node";
import { toast } from 'react-toastify';
import { getRequestPayload, postRequestPayload } from "../services/apiServices";

interface GraphState {
    name: string,
    description: string,
    graphObject: {
        edges: DagableEdge[],
        nodes: DagableNode[]
    },
    schedule: {
        processorCount: number,
        maxLengthRoundedUp: number,
        nodeProcessorMappings: any[]
    },
    isStandard: boolean,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string,
}

const initialState = {
    graphObject: {
        edges: [],
        nodes: [],
    },
    schedule: {
        processorCount: 0,
        maxLengthRoundedUp: 0,
        nodeProcessorMappings: []
    },
    isStandard: true,
    status: "idle",
    error: ''
} as GraphState

const graphSlice = createSlice({
    name: "graph",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDefaultGraph.pending, (state) => {
                state.status = "loading";
                state.isStandard = true;
            })
            .addCase(fetchDefaultGraph.fulfilled, (state, action) => {
                state.graphObject = {
                    ...state.graphObject,
                    nodes: action.payload.graph.nodes,
                    edges: action.payload.graph.edges,
                };
                state.status = "succeeded";
            })
            .addCase(fetchDefaultGraph.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message ?? '';
            })
            .addCase(fetchGraphByGuid.pending, (state) => {
                state.status = "loading";
                state.isStandard = true;
            })
            .addCase(fetchGraphByGuid.fulfilled, (state, action) => {
                state.graphObject = {
                    ...state.graphObject,
                    nodes: action.payload.result.nodes,
                    edges: action.payload.result.edges,
                };
                state.name = action.payload.result.name
                state.description = action.payload.result.description
                state.status = "succeeded";
            })
            .addCase(fetchGraphByGuid.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message ?? '';
            })
            .addCase(postDefaultGraph.pending, (state) => {
                state.status = "loading";
                state.isStandard = true;
            })
            .addCase(postDefaultGraph.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.graphObject = {
                    nodes: action.payload.graph.nodes,
                    edges: action.payload.graph.edges,
                };
            })
            .addCase(postDefaultGraph.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message ?? '';
            })
             .addCase(rescheduleGraph.pending, (state) => {
                state.status = "loading";
                state.isStandard = false;
            })
            .addCase(rescheduleGraph.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.schedule = {
                    maxLengthRoundedUp: action.payload.maxLengthRoundedUp,
                    processorCount: action.payload.processorCount,
                    nodeProcessorMappings: action.payload.nodeProcessorMappings
                 }
            })
            .addCase(rescheduleGraph.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message ?? '';
            })
            .addCase(postCriticalPathGraph.pending, (state) => {
                state.status = "loading";
                state.isStandard = false;
            })
            .addCase(postCriticalPathGraph.fulfilled, (state, action) => {
                state.status = "succeeded";
                var resultGraph = action.payload.graph;
                state.graphObject = {
                    nodes: resultGraph.nodes,
                    edges: resultGraph.edges,
                };
                state.schedule = {
                   maxLengthRoundedUp: action.payload.schedule.maxLengthRoundedUp,
                   processorCount: action.payload.schedule.processorCount,
                   nodeProcessorMappings: action.payload.schedule.nodeProcessorMappings
                }
            })
            .addCase(postCriticalPathGraph.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message ?? '';
            })
            .addCase(saveGraph.pending, (state) => {
                state.status = "loading";
            })
            .addCase(saveGraph.fulfilled, (state) => {
                state.status = "succeeded";
                toast.success("Graph saved successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT})
            })
            .addCase(saveGraph.rejected, (state) => {
                state.status = "failed";
            });
    }
});

export default graphSlice.reducer;

export const saveGraph = createAsyncThunk(
    "graph/save",
    async (requestObject: any) => await postRequestPayload<any>('graphs/save', requestObject)
)

export const fetchGraphByGuid = createAsyncThunk(
    "graph/getSpecific",
    async (guid: string) => await getRequestPayload(`graphs/${guid}`)
)

export const fetchDefaultGraph = createAsyncThunk(
    "graph/generate",
    async () => await getRequestPayload('graphs/generate/standard')
);

export const postDefaultGraph = createAsyncThunk(
    "graph/generate/standard",
    async (requestObject: StandardGraphDTO) => await postRequestPayload<StandardGraphDTO>('graphs/generate/standard', requestObject)
);

export const postCriticalPathGraph = createAsyncThunk(
    "graph/generate/critical-path",
    async (requestObject: CriticalGraphDTO) => await postRequestPayload<CriticalGraphDTO>('graphs/generate/critical-path', requestObject)
)

export const rescheduleGraph = createAsyncThunk(
    "graph/reschedule",
    async (requestObject: any) => await postRequestPayload<any>('graphs/schedule/reschedule', requestObject)
)
