import React, { useEffect } from "react";
import { signinRedirectCallback } from "../services/userService";
import { useNavigate } from "react-router-dom";
import FullPanelLoader from "../components/layout/fullPanelLoader";

export default function SigninOidc() {
  const navigate = useNavigate();
  useEffect(() => {
    async function signinAsync() {
      await signinRedirectCallback()
      navigate('/')
    }
    signinAsync()
  }, [navigate])

  return (
     <FullPanelLoader message="Logging In...">
    </FullPanelLoader>
  );
}

