import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setAuthHeader } from "../utils/axiosHeaders";

interface AuthState {
    user: any | null,
    isLoadingUser: boolean
}

const initialState = {
    user: null,
    isLoadingUser: false
} as AuthState

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        storeUser: (state, action: PayloadAction<AuthState>) => {
            state.isLoadingUser = false
            state.user = action.payload
            setAuthHeader(state?.user?.access_token);
        },
        loadingUser: (state) => {
            state.isLoadingUser = true
        },
        userSignedOut: (state) => {
            state.user = null
            state.isLoadingUser = false
        },
        userExpired: (state) => {
            state.user = null
            state.isLoadingUser = false
        },
        storeUserError: (state) => {
            state.user = null
            state.isLoadingUser = false
        }
    },
})

export const { storeUser, loadingUser, userSignedOut, userExpired, storeUserError  } = authSlice.actions
export default authSlice.reducer
