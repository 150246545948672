import React from "react";
import { Button, Container } from "react-bootstrap";
import { signinRedirect } from "../services/userService";

export default function Unauthorised() {
  return (
    <Container fluid className="homepage-container">
      <div
        className="d-flex align-items-center justify-content-center fof"
        style={{ height: "100%" }}
      >
        <div>
          <h1>Unauthorised</h1>
          <div className="d-flex align-items-center justify-content-center">
            <Button
              onClick={() => signinRedirect()}
              variant="dagable"
              size="sm"
              className="ms-2"
            >
              Sign In
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
}
