import { User } from "oidc-client-ts";
import React from "react";
import { Nav, Navbar, Button, Container } from "react-bootstrap";
import { useProSidebar } from "react-pro-sidebar";
import { useSelector } from "react-redux";
import { signinRedirect } from "../../services/userService";
import { RootState } from '../../app/store'
import { List } from "react-bootstrap-icons";


function DagableNav() {
    const user: User = useSelector((state: RootState) => state.auth.user);
    function login() {
        signinRedirect();
    }
    const { collapseSidebar } = useProSidebar();
    function renderLoginButton() {
        return user === null ? (
            <Nav>
                <Button onClick={() => login()} variant="dagable" size="sm" className="ms-2">
                    Sign In
                </Button>
            </Nav>
        ) : (
            ''
        );
    }

    return (
        <Navbar expand="lg" bg="dark" variant="dark" style={{ height: '48px' }}>
            <Container fluid className="pe-5">
                <div>
                    <List size={28} color="#f16f4e" onClick={() => collapseSidebar()} />
                </div>
                <Navbar.Collapse className="justify-content-end">
                    {renderLoginButton()}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default DagableNav;
