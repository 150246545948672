import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getRequestPayload, postRequestPayload } from '../services/apiServices';


interface IUserSettingsDTO {
    nodeColor: string,
    nodeShape: 'circle' | 'box',
    useVerticalLayout: boolean
}

interface IUserGraphHeading {
    name: string,
    description: string,
    createdOn: Date,
    graphGuid: string
}

interface IUserSettingsState {
    userSettings: IUserSettingsDTO,
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string,
    userGraphs: IUserGraphHeading[]
}

const initialState = {
    userSettings: {
        nodeColor: '#f16f4e',
        nodeShape: 'circle',
        useVerticalLayout: true
    },
    userGraphs: [],
    status: 'idle',
    error: '',
} as IUserSettingsState

export const fetchUserSettings = createAsyncThunk(
    "users/me",
    async () => await getRequestPayload('users/me')
);

export const fetchUserGraphs = createAsyncThunk(
    "users/me/my-graphs",
    async () => await getRequestPayload('users/me/my-graphs')
)

export const updateUserSettings = createAsyncThunk(
    "users/me/update-settings",
    async (requestObject: IUserSettingsDTO) => await postRequestPayload<IUserSettingsDTO>('users/me/update-settings', requestObject)
)

const userSlice = createSlice({
    name: "user",
    initialState: initialState,
    reducers: {
        setNodeColor: (state, action: PayloadAction<string>) => {
            state.userSettings.nodeColor = action.payload
        },
        setNodeStyle: (state, action: PayloadAction<'circle' | 'box'>) => {
            state.userSettings.nodeShape = action.payload
        },
        setLayout: (state, action: PayloadAction<boolean>) => {
            state.userSettings.useVerticalLayout = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserSettings.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchUserSettings.fulfilled, (state, action) => {
                state.userSettings = {
                    ...state.userSettings,
                    nodeColor: action.payload.nodeColor,
                    nodeShape: action.payload.nodeShape,
                    useVerticalLayout: action.payload.useVerticalLayout
                };
                state.status = "succeeded";
            })
            .addCase(fetchUserSettings.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message ?? '';
            })
            .addCase(updateUserSettings.pending, (state) => {
                state.status = "loading";
            })
            .addCase(updateUserSettings.fulfilled, (state, action) => {
                state.userSettings = {
                    ...state.userSettings,
                    nodeColor: action.payload.nodeColor,
                    nodeShape: action.payload.nodeShape,
                    useVerticalLayout: action.payload.useVerticalLayout
                };
                state.status = "succeeded";
                toast.success("User settings saved successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT})
            })
            .addCase(updateUserSettings.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message ?? '';
            })
            .addCase(fetchUserGraphs.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchUserGraphs.fulfilled, (state, action) => {
                state.userGraphs = action.payload;
                state.status = "succeeded";
            })
            .addCase(fetchUserGraphs.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message ?? '';
            })
    }
})

export const { setNodeColor, setNodeStyle, setLayout} = userSlice.actions
export { IUserGraphHeading }
export default userSlice.reducer;