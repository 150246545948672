import React from 'react'
import { Container } from 'react-bootstrap'

export default function NotFound() {
  return (
    <Container fluid className="homepage-container">
      <div
        className="d-flex align-items-center justify-content-center ps-5 pt-5 fof"
        style={{ height: "100%" }}
      >
        <h1>Not Found</h1>
      </div>
    </Container>
  )
}