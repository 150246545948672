import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FiletypeJson } from "react-bootstrap-icons";
import { JsonViewer } from "@textea/json-viewer";

export default function JsonModal(props: any) {
  return (
    <Modal
      show={props.show}
      onHide={() => props.onClose()}
      className="modal-z-index"
      dialogClassName="modal-50w"
      aria-labelledby="example-custom-modal-styling-title"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          <span>
            <FiletypeJson className="bi" /> {props.name}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <JsonViewer value={props.jsonObject} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dagable" onClick={() => props.onClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
