import React from 'react';
import { toast } from 'react-toastify';

interface DagableError {
    code: string,
    description: string
}

interface StandardApiResponse {
    data: any;
    isError: boolean;
    errors?: DagableError[]
}

function parseApiResponse(apiResponse: StandardApiResponse) {
    if(apiResponse.isError){
        toast.error((<ul>{formatErrors(apiResponse.errors)}</ul>),{
            position: toast.POSITION.BOTTOM_RIGHT})
        return;
    } 
    return apiResponse.data
}

function formatErrors(errors?: DagableError[]){
    return errors?.map(x => { return (<li key={x.code}>{x.description}</li>)})
}

export default parseApiResponse;