import axios from "axios";
import parseApiResponse from "../utils/apiResponseResolver";

export async function postRequestPayload<T>(uri: string, requestObject: T) {
  return await axios
    .post(`${process.env.REACT_APP_API_URL}${uri}`, requestObject)
    .catch((errorResponse) => parseApiResponse(errorResponse.response.data))
    .then((successResponse) => parseApiResponse(successResponse.data));
}

export async function getRequestPayload(url: string) {
  return await axios
    .get(`${process.env.REACT_APP_API_URL}${url}`)
    .catch((errorResponse) => parseApiResponse(errorResponse.response.data))
    .then((successResponse) => parseApiResponse(successResponse.data));
}
