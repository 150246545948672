import React from 'react'
import { Col, Container, Row, Accordion, Alert } from 'react-bootstrap'

function About() {
  return (
    <Container className='about-container'>
      <Row>
        <Col>
          <h1>About
          </h1>
          <hr />
          <p>Dagable is an application dedicated to the generation and scheduling of Directed Acyclic Graphs</p>

          <p>Currently supports creating DAG's using the layer-by-layer method and scheduling using the DLS algorithm.</p>
          <h1>FAQ
          </h1>
          <hr />
          <Accordion alwaysOpen flush>
            <Accordion.Item eventKey="3">
              <Accordion.Header><h2>Why do I need to authenitcate to create custom DAGs?</h2></Accordion.Header>
              <Accordion.Body>
                <p>This whole web application is currently being ran on a Raspberry Pi 4. Creating DAGs is computationally expensive - it should stop and reduce spam requests.</p>
                <Alert key="warning" variant="warning">
                  You can use the username <strong>testuser</strong> and password <strong>Testuser123-</strong> to gain access in this pre-alpha version.
                </Alert>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="0">
              <Accordion.Header><h2>What is a DAG?</h2></Accordion.Header>
              <Accordion.Body>
                <p>A directed acyclic graph (DAG) is a directed graph with no cycles. That is, it is a graph that consists of a set of vertices connected by edges, with each edge directed from one vertex to another, such that there is no way to start at any vertex v and follow a sequence of edges that eventually loops back to v again.</p>

                <p>DAGs are often used to represent relationships between data items, and they can be used to model processes or systems in which the flow of information or other dependencies between events is important. They are commonly used in a variety of contexts, including data modeling, scheduling, and version control.</p>

                <p>In a DAG, the vertices represent the data items or events, and the edges represent the dependencies or relationships between them. The edges are directed, meaning that they have a specific direction and only allow information to flow in that direction. This means that a DAG can represent asymmetric relationships, where the relationship between two vertices is not necessarily the same in both directions.</p>

                <p>There are many algorithms and data structures that are specifically designed for use with DAGs, and they are a fundamental tool in computer science and many other fields.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header><h2>How do you create DAGs?</h2></Accordion.Header>
              <Accordion.Body>
                <p>There are several ways to create directed acyclic graphs (DAGs). One simple method is to start with a set of vertices and then add edges between them. To ensure that the graph is acyclic, you can follow these steps:</p>
                <ol>
                  <li>Choose a vertex to start with and label it as the "current" vertex.</li>
                  <li>Consider each of the vertices that are not yet in the graph. For each of these vertices, determine whether adding an edge from the current vertex to it would create a cycle. If it would not create a cycle, add an edge from the current vertex to the other vertex and label the other vertex as the current vertex. If it would create a cycle, do not add the edge.</li>
                  <li>Repeat step 2 until all vertices have been considered.</li>
                </ol>
                <p>Another way to create a DAG is to use a topological sort algorithm on a graph that has no directed cycles. A topological sort is an ordering of the vertices in a directed graph such that, for every edge uv from vertex u to vertex v, u comes before v in the ordering. If a graph has a topological sort, it must be a DAG.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header><h2>What do you mean by scheduling a DAG?</h2></Accordion.Header>
              <Accordion.Body>
                <p>Scheduling a directed acyclic graph (DAG) means determining an order in which to perform the tasks represented by the vertices of the graph, taking into account the dependencies between the tasks represented by the edges of the graph.</p>
                <p>Another way to create a DAG is to use a topological sort algorithm on a graph that has no directed cycles. A topological sort is an ordering of the vertices in a directed graph such that, for every edge uv from vertex u to vertex v, u comes before v in the ordering. If a graph has a topological sort, it must be a DAG.</p>
                <p>In some cases, it may be necessary to use a heuristic or approximate algorithm to schedule a DAG, especially if the graph is large or the dependencies between tasks are complex. These algorithms can be useful for finding a good, but not necessarily optimal, solution to the scheduling problem.</p>
                <p>In this application we focus on heuristic algorithms within homogeneous environments.</p>
                <p>At present we implement the Dynamic level scheduling (DLS) algorithm.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header><h2>What is the Dynamic Level Scheduling (DLS) Algorithm?</h2></Accordion.Header>
              <Accordion.Body>
                <p>Dynamic level scheduling is a method for scheduling tasks in a directed acyclic graph (DAG) that takes into account the dynamic nature of the graph, meaning that the graph may change over time by the addition or removal of vertices and edges.</p>
                <p>In dynamic level scheduling, the vertices of the DAG are divided into levels, with the vertices at the highest level representing tasks that have no dependencies and can be started immediately, and the vertices at lower levels representing tasks that depend on the completion of one or more tasks at higher levels. As tasks are completed, new tasks may become eligible to be started, and the DAG may change as a result.</p>
                <p>The goal of dynamic level scheduling is to maximize the parallelism of the DAG by starting as many tasks as possible at the same time, while still respecting the dependencies between tasks. To do this, the scheduler must continuously monitor the status of the tasks and update the schedule as the DAG changes.</p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header><h2>How is this built/hosted?</h2></Accordion.Header>
              <Accordion.Body>
                Its currently a work in progress passion project - you can follow my progress on <a href="https://blog.jwm.xyz/" target="_blank" rel="noopener noreferrer">my blog.</a>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header><h2>Will this ever be open source?</h2></Accordion.Header>
              <Accordion.Body>
                <p>I have no plans to make this fully open source at the moment. Its still very early days. </p>
                <p>The core functionality packages <a href="https://www.nuget.org/packages?q=dagable.core">Dagable.Core</a> has been made open source and can be viewed on <a href='https://github.com/dagable/Dagable.Core'>GitHub</a></p>
                <p>Feel free to reach out if you have any questions!</p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>


        </Col>
      </Row>
    </Container>
  )
}

export default About
