import { configureStore } from '@reduxjs/toolkit';
import authReducer from "../store/authSlice";
import graphReducer from '../store/graphSlice';
import graphInteractionReducer from "../store/graphInteractionSlice";
import userReducer from '../store/userSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    graph: graphReducer,
    graphInteraction: graphInteractionReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch