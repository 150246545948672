import React from 'react'
import { Col, Container } from 'react-bootstrap'
import DagableGraph from '../components/graphScheduling/dagableGraph'
import DagableGraphSettings from '../components/graphScheduling/dagableGraphSettings'
import DagableSchedule from '../components/graphScheduling/dagableSchedule'
import { Allotment } from "allotment";
import { useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import { RootState } from "../app/store";

function Home() {
  const isStandard = useSelector((state: RootState) => state.graph.isStandard)
  const dagStatus = useSelector((state: RootState) => state.graph.status)

  const renderSpinnerIfLoading = (element: JSX.Element) =>{
    if (dagStatus === 'loading') {
      return(<Spinner animation="border" role="status" variant="dagable">
      <span className="visually-hidden">Loading...</span>
      </Spinner>)
    }
    return element
  }

  return (
    <Container fluid className="homepage-container">
      <Allotment defaultSizes={[500, 200, 300]}>
        <Allotment.Pane>
          <DagableGraph />
        </Allotment.Pane>
        <Allotment.Pane minSize={315} maxSize={400} visible={!isStandard}>
          {renderSpinnerIfLoading(<DagableSchedule />)}
        </Allotment.Pane>
        <Allotment.Pane minSize={270} preferredSize={"400px"} maxSize={400} >
          <Col className="settings-pane py-2 ps-3 pe-3">
            <DagableGraphSettings />
          </Col>
        </Allotment.Pane>
      </Allotment>
    </Container>

  )
}

export default Home
