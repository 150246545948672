import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useCookies } from "react-cookie";
import { MegaphoneFill } from "react-bootstrap-icons";
import { Button, Col, Row } from 'react-bootstrap';
import DagableLogo from './../../assets/logo.png';


export default function WelcomeModal() {
    const [cookies, setCookie] = useCookies(["WelcomePopupSeen"]);
    const [show, setShow] = useState(cookies["WelcomePopupSeen"] !== "true");

    const closeModal = () => {
        setCookie("WelcomePopupSeen", true, { path: "/", expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) });
        setShow(false)
    }

    return (
        <>
            <Modal
                show={show}
                onHide={() => closeModal()}
                className="modal-z-index"
                dialogClassName="modal-50w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title id="example-custom-modal-styling-title">
                        <span><MegaphoneFill className='bi' /> Welcome - Dagable.com</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col xs={6}>
                        <img src={DagableLogo} style={{"width": "100%", "padding": "50px"}} alt="logo" />
                        </Col>
                        <Col xs={6}>
                            <p>
                                <h4>Welcome!</h4>
                            </p>
                            <p>
                                Dagable.com is a web application dedicated to the generation and scheduling of directed acyclic graphs.
                            </p>
                            <p>This is a <strong>pre-alpha</strong> release. Any saved graphs may be deleted and there may be bugs.</p>
                            <p>More information can be found in the about page.</p>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dagable" onClick={closeModal}>
                        I Understand
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}