import React, { useEffect, useMemo, useState } from "react";
import {
  Badge,
  Col,
  Container,
  ListGroup,
  Nav,
  Navbar,
  Row,
} from "react-bootstrap";
import { isUserLoaded } from "../services/userService";
import { RootState } from "../store";
import { fetchUserGraphs, IUserGraphHeading } from "../store/userSlice";
import { GRAPH_OPTIONS } from "../components/constants/dagableGraphConsts";
import Graph from "react-graph-vis";
import { v4 as uuidv4 } from "uuid";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import FullPanelLoader from "../components/layout/fullPanelLoader";
import Moment from "react-moment";
import { fetchGraphByGuid } from "../store/graphSlice";
import JsonModal from "../components/myGraphs/jsonModal";

export default function MyGraphs() {
  const dispatch = useAppDispatch();
  const userGraphs = useAppSelector(
    (state: RootState) => state.user.userGraphs
  );
  const userSettings = useAppSelector(
    (state: RootState) => state.user.userSettings
  );
  const dagStatus = useAppSelector((state: RootState) => state.graph.status);
  const dag = useAppSelector((state: RootState) => state.graph);
  const [showModal, setShowModal] = useState(false);
  const version = useMemo(uuidv4, [dag]);
  const [activeGraph, setActiveGraph] = useState<IUserGraphHeading | null>(null);

  useEffect(() => {
    const userLoaded = async () => {
      if (await isUserLoaded()) {
        dispatch(fetchUserGraphs());
      }
    };
    userLoaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  var graphSettings = {
    ...GRAPH_OPTIONS,
    nodes: {
      ...GRAPH_OPTIONS.nodes,
      shape: userSettings.nodeShape,
      color: userSettings.nodeColor,
    },
  };

  const selectGraph = (graph: IUserGraphHeading) => {
    setActiveGraph(graph);
    dispatch(fetchGraphByGuid(graph.graphGuid));
  };

  const renderUserGraphs = () => {
    return (
      <ListGroup as="ol">
        {userGraphs.map((graph, index) => {
          return (
            <ListGroup.Item
              action
              as="li"
              className="d-flex justify-content-between align-items-start"
              key={graph.graphGuid}
              active={activeGraph?.graphGuid === graph.graphGuid}
              onClick={() => selectGraph(graph)}
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">{graph.name}</div>
                {graph.description}
              </div>
              <Badge bg="primary" pill>
                <Moment format="DD/MM/YYYY hh:mm">{graph.createdOn}</Moment>
              </Badge>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  };

  const renderGraph = () => {
    return dagStatus === "loading" ? (
      <FullPanelLoader message="Loading graph..."></FullPanelLoader>
    ) : (
      <>
        <Row>
          {dag.graphObject?.edges.length > 0 && (
              <Navbar bg="light" variant="dagable" className="pt-0 pb-0">
                <Container fluid>
                  <Navbar.Brand href="#home">{dag?.name}</Navbar.Brand>
                </Container>
                <Nav className="justify-content-end">
                  <Nav.Item>
                    <Nav.Link onClick={() => { setShowModal(true) }}>JSON</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar>
          )}
        </Row>
        <Row style={{ height: "100%" }}>
          <Graph key={version} graph={dag.graphObject} options={graphSettings} />
        </Row>
      </>
    );
  };

  return (
    <Container fluid className="homepage-container">
      {userGraphs == null || userGraphs.length === 0 ? (
        <Row>
          <Col md={{ span: 3, offset: 5 }}>No graphs saved</Col>
        </Row>
      ) : (
        <Row style={{ height: "100%" }}>
          <Col xs="3">{renderUserGraphs()}</Col>
          <Col>{renderGraph()}</Col>
        </Row>
      )}
      <JsonModal show={showModal} name={dag?.name} onClose={() => setShowModal(false)} jsonObject={dag?.graphObject} />
    </Container>
  );
}
