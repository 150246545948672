import { UserManager } from 'oidc-client-ts';
import { storeUser, storeUserError } from '../store/authSlice';
import { fetchUserSettings } from '../store/userSlice';

const CODE_CHALLENGE = base64URLEncode(sha256(base64urlencode(generateCodeVerifier())));

const config = {
  authority: `${process.env.REACT_APP_AUTH_URL}`,
  audience: "dagableWeb",
  client_id: `${process.env.REACT_APP_CLIENT_ID}`,
  redirect_uri:  `${process.env.REACT_APP_URL}signin-oidc`,
  response_type: "code",
  grant_tpe: "authorization_code",
  code_challenge: CODE_CHALLENGE,
  code_challenge_method: 'S256',
  scope: "openid profile dagableApi offline_access",
  post_logout_redirect_uri: `${process.env.REACT_APP_URL}signout-callback-oidc`,
};

function generateCodeVerifier() {
  var array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join("");
}
function dec2hex(dec) {
  return ("0" + dec.toString(16)).substring(-2);
}
function sha256(plain) {
  // returns promise ArrayBuffer
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
}

function base64urlencode(a) {
  var str = "";
  var bytes = new Uint8Array(a);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

function base64URLEncode(str) {
  return str.toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');
}

const userManager = new UserManager(config)

export async function isUserLoaded(){
  return await userManager.getUser() !== null
}

export async function loadUserFromStorage(store) {
  try {
    let user = await userManager.getUser()
    if (!user) { return store.dispatch(storeUserError()) }
    store.dispatch(storeUser(user))
    store.dispatch(fetchUserSettings())
  } catch (e) {
    console.error(`User not found: ${e}`)
    store.dispatch(storeUserError())
  }
}

export function signinRedirect() {
  return userManager.signinRedirect()
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}

export function signoutRedirect() {
  userManager.clearStaleState()
  userManager.removeUser()
  return userManager.signoutRedirect()
}

export function signoutRedirectCallback() {
  userManager.clearStaleState()
  userManager.removeUser()
  return userManager.signoutRedirectCallback()
}

export default userManager