import React from 'react'
import { Col, Container, ListGroup, Row, Tab } from 'react-bootstrap'
import DisplaySettings from '../components/settings/DisplaySettings'

export default function Settings() {
    return (
        <Container className='about-container'>
            <Tab.Container id="list-group-tabs-example" defaultActiveKey="#display-settings">
                <Row>
                    <Col sm={4}>
                        <ListGroup className='no-overflow'>
                            <ListGroup.Item action href="#display-settings">
                                Display Settings
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col sm={8}>
                        <Tab.Content>
                            <Tab.Pane eventKey="#display-settings">
                                <DisplaySettings />
                            </Tab.Pane>
                            <Tab.Pane eventKey="#link2">
                                <span>TODO</span>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </Container>
    )
}
