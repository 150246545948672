import React, { useCallback, useEffect } from "react";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { useSelector } from "react-redux";
import { RootState } from '../../app/store'


export default function DagableSchedule() {
    const { editor, onReady } = useFabricJSEditor();
    const dag = useSelector((state: RootState) => state.graph.schedule)
    const selectedNode = useSelector((state: RootState) => state.graphInteraction.selectedNodeId)
    var gridTimeSpanIncrement = 10;
    var grid = 60;
    var defaultWidth = 50;
    var defaultLeftOffset = 5;

    const _onReady = useCallback((canvas: fabric.Canvas) => {
        canvas.clear();
        if (dag.nodeProcessorMappings !== undefined && Object.keys(dag.nodeProcessorMappings).length > 0) {
            renderGrid(canvas);
            renderNodes(canvas);
            registerCanvasMouseEvents(canvas);

            canvas.setDimensions({
                width: 400
            });
        }

        canvas.uniformScaling = true;
        canvas.renderAll();

        onReady(canvas);
    },[selectedNode]);

    useEffect(() => {
        if (editor !== undefined && editor.canvas !== undefined) {
            _onReady(editor?.canvas)
        }
    }, [dag, _onReady, editor])

    const registerCanvasMouseEvents = (canvas: fabric.Canvas) => {
        canvas.on('mouse:down', function (opt) {
            var evt = opt.e;
            this.isDragging = true;
            this.selection = false;
            this.lastPosX = evt.clientX;
            this.lastPosY = evt.clientY;
        });
        canvas.on('mouse:move', function (opt) {
            if (this.isDragging) {
                var e = opt.e;
                var zoom = canvas.getZoom();
                var vpt = this.viewportTransform;
                if (zoom < 0.4) {
                    vpt[4] = 200 - 10 * zoom / 2;
                    vpt[5] = 200 - 10 * zoom / 2;
                } else {
                    vpt[4] += e.clientX - this.lastPosX;
                    vpt[5] += e.clientY - this.lastPosY;
                    if (vpt[4] >= 0) {
                        vpt[4] = 0;
                    } else if (vpt[4] < canvas.getWidth() - 10 * zoom) {
                        vpt[4] = canvas.getWidth() - 10 * zoom;
                    }
                    if (vpt[5] >= 0) {
                        vpt[5] = 0;
                    } else if (vpt[5] < canvas.getHeight() - 10000 * zoom) {
                        vpt[5] = canvas.getHeight() - 10000 * zoom;
                    }
                }
                this.requestRenderAll();
                this.lastPosX = e.clientX;
                this.lastPosY = e.clientY;
            }
        });
        canvas.on('mouse:up', function (opt) {
            // on mouse up we want to recalculate new interaction
            // for all objects, so we call setViewportTransform
            this.setViewportTransform(this.viewportTransform);
            this.isDragging = false;
            this.selection = true;
        });
    }

    const renderGrid = (canvas: fabric.canvas) => {
        const columns = dag.processorCount;
        const rows = (dag.maxLengthRoundedUp / gridTimeSpanIncrement);
        const width = ((dag.processorCount + 1) * grid)
        for (let colCount = 0; colCount <= columns + 1; colCount++) {
            canvas.add(new fabric.Line([colCount * grid, 0, colCount * grid, (rows + 1) * grid], { stroke: '#ccc', selectable: false }));
        }

        for (let rowCount = 0; rowCount <= rows + 1; rowCount++) {
            canvas.add(new fabric.Line([0, rowCount * grid, width, rowCount * grid], { stroke: '#ccc', selectable: false }))
        }

        //column headings
        for (let colHeadingCount = 0; colHeadingCount < columns; colHeadingCount++) {
            canvas.add(new fabric.Textbox('pr ' + (colHeadingCount + 1), {
                width: defaultWidth,
                top: 20,
                left: (colHeadingCount * grid) + grid,
                fontSize: 11,
                selectable: false,
                textAlign: 'center',
                fixedWidth: grid
            }));
        }

        //row timings
        for (let i = 0; i <= rows; i++) {
            canvas.add(new fabric.Textbox(' ' + (i * gridTimeSpanIncrement), {
                width: defaultWidth,
                top: i * grid + 55,
                left: 0,
                fontSize: 11,
                textAlign: 'center',
                fixedWidth: grid,
                selectable: false,
            }));
        }
    }

    const renderNodes = (canvas: fabric.Canvas) => {
        for (const key in dag.nodeProcessorMappings) {
            for (let i = 0; i < dag.nodeProcessorMappings[key].length; i++) {
                const node = dag.nodeProcessorMappings[key][i];
                if (node.endAt - node.startAt === 0) {
                    continue;
                }
                canvas.add(new fabric.Rect({
                    left: defaultLeftOffset + grid + (grid * Number(key)),
                    top: grid + ((node.startAt) * 6),
                    width: defaultWidth,
                    height: (node.endAt - node.startAt) * 6,
                    selectable: true,
                    stroke: "black",
                    fill: '#faa',
                    originX: 'left',
                    originY: 'top',
                    centeredRotation: true,
                    type: "rect_" + node.id
                }));
                canvas.add(new fabric.Textbox('N' + node.id + '\n ' + (node.endAt - node.startAt), {
                    width: defaultWidth,
                    top: grid + (node.startAt * 6),
                    left: defaultLeftOffset + grid + (grid * Number(key)),
                    fontSize: 11,
                    selectable: false,
                    textAlign: 'center',
                    fixedWidth: 150
                }));

            }
        }

        const nodeObjects = canvas.getObjects().filter(x => x.type.split('_')[0] === "rect")
        for (let j = 0; j < nodeObjects.length; j++) {
            const nodeId = nodeObjects[j].type.split('_')[1]
            if (Number(nodeId) === selectedNode) {
                nodeObjects[j].fill = '#ea6464'
            }
        }
    }

    return (
            <FabricJSCanvas className="sample-canvas"  onReady={_onReady} />
    );
}