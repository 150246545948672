import React, { useState } from 'react'
import reactCSS from 'reactcss'
import { SketchPicker } from "@hello-pangea/color-picker";
import { setNodeColor } from '../../store/userSlice';
import { useDispatch } from "react-redux";

export default function ColorPicker(props){
  var dispatch = useDispatch();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const styles = reactCSS({
    'default': {
      color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `${ props.color }`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  };

  const handleClose = () => {
    setDisplayColorPicker(false)
  };

  const handleChange = (color) => {
    dispatch(setNodeColor(color.hex))
  };

  return (
    <div>
      <div style={ styles.swatch } onClick={ handleClick }>
        <div style={ styles.color } />
      </div>
      { displayColorPicker ? <div style={ styles.popover }>
        <div style={ styles.cover } onClick={ handleClose }/>
        <SketchPicker color={props.color} onChange={ handleChange } />
      </div> : null }

    </div>
  )
}
