import { User } from "oidc-client-ts";
import React from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import {
  QuestionCircleFill,
  Power,
  Globe,
  Github,
  PersonBadgeFill,
  ListColumns,
} from "react-bootstrap-icons";
import SigninOidc from "../../pages/signin-oidc";
import SignoutOidc from "../../pages/signout-oidc";
import Settings from "../../pages/settings";
import Home from "../../pages/home";
import About from "../../pages/about";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import DagableNav from "./dagableNavbar";
import { signinRedirect, signoutRedirect } from "../../services/userService";
import { ReactComponent as ReactLogo } from "./../../assets/logo2.svg";
import MyGraphs from "../../pages/my-graphs";
import ProtectedRoute from "../../utils/protectedRoute";
import NotFound from "../../pages/notfound";
import Unauthorised from "../../pages/unauthorised";

function DagableMainSideBar() {
  const user: User = useSelector((state: RootState) => state.auth.user);
  const location = useLocation();

  function isLinkActive(linkUri: string) {
    return location.pathname === "/" + linkUri;
  }

  return (
    <div style={{ display: "flex", minHeight: "100%" }}>
      <Sidebar backgroundColor="rgba(52, 58, 64, 1)" transitionDuration={1000}>
        <Menu
          renderMenuItemStyles={({ level, disabled, active }) => ({
            ".sub-menu-content": {
              backgroundColor: "unset",
            },
            ".menu-anchor": {
              color: active ? "#f16f4e" : "#a8a8a8",
              backgroundColor: "unset",
            },
            ".menu-anchor:hover": {
              backgroundColor: "#24282d",
              color: active ? "#ec9984" : "#d0d0d0",
            },
          })}
        >
          <MenuItem
            icon={<ReactLogo />}
            routerLink={<Link to="/" />}
            active={isLinkActive("")}
          >
            Home
          </MenuItem>
          {user && (
            <MenuItem
              icon={<PersonBadgeFill size={28} />}
              routerLink={<Link to="/settings" />}
              active={isLinkActive("settings")}
            >
              Account Settings
            </MenuItem>
          )}
          {user && (
            <MenuItem
              icon={<ListColumns size={28} />}
              routerLink={<Link to="/my-graphs" />}
              active={isLinkActive("my-graphs")}
            >
              {" "}
              My Graphs
            </MenuItem>
          )}
          <SubMenu
            label="About"
            icon={<QuestionCircleFill size={28} />}
            active={isLinkActive("about")}
          >
            <MenuItem
              icon={<QuestionCircleFill />}
              routerLink={<Link to="/about" />}
              active={isLinkActive("about")}
            >
              {" "}
              About{" "}
            </MenuItem>
            <MenuItem
              icon={<Globe />}
              routerLink={
                <a href="https://jwm.xyz" target="_blank" rel="noreferrer" />
              }
            >
              Website
            </MenuItem>
            <MenuItem
              icon={<Github />}
              routerLink={
                <a
                  href="https://github.com/jwmxyz"
                  target="_blank"
                  rel="noreferrer"
                />
              }
            >
              {" "}
              Github
            </MenuItem>
          </SubMenu>
          {user ? (
            <MenuItem
              icon={<Power size={28} />}
              onClick={() => signoutRedirect()}
            >
              Sign out
            </MenuItem>
          ) : (
            <MenuItem
              icon={<Power size={28} />}
              onClick={() => signinRedirect()}
            >
              Sign in
            </MenuItem>
          )}
        </Menu>
      </Sidebar>
      <div style={{ width: "100%" }}>
        <DagableNav />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-graphs"
              element={
                <ProtectedRoute>
                  <MyGraphs />
                </ProtectedRoute>
              }
            />
            <Route path="/signout-oidc" element={<SignoutOidc />} />
            <Route path="/signin-oidc" element={<SigninOidc />} />
            <Route path="/unauthorised" element={<Unauthorised />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}

export default DagableMainSideBar;
