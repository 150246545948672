import React, { useEffect, useMemo  } from "react";
import Graph from "react-graph-vis";
import { useDispatch, useSelector } from "react-redux";
import { postCriticalPathGraph } from '../../store/graphSlice'
import { selectGraphNode } from '../../store/graphInteractionSlice';
import { v4 as uuidv4 } from 'uuid'
import { Spinner } from 'react-bootstrap'
import { GRAPH_OPTIONS } from '../constants/dagableGraphConsts'
import { RootState } from "../../app/store";

function DagableGraph() {
    const dispatch = useDispatch();
    const dag = useSelector((state: RootState) => state.graph.graphObject)
    const dagStatus = useSelector((state: RootState) => state.graph.status)
    const userSettings = useSelector((state: RootState) => state.user.userSettings)
    const version = useMemo(uuidv4, [dag]);
    
    useEffect(() => {
        dispatch(postCriticalPathGraph({
            nodes: 10,
            layers: 5,
            percentage: 5,
            maxComm: 45,
            minComm: 3,
            maxComp: 20,
            minComp: 1,
            processors: 1,
          }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const events = {
        select: function(event: any) {
            var selectedNodeId = event.nodes[0]
            dispatch(selectGraphNode(selectedNodeId))
        }
      };

    const graphSettings = {
        ...GRAPH_OPTIONS, 
        nodes: {
            ...GRAPH_OPTIONS.nodes,
            shape: userSettings.nodeShape,
            color: userSettings.nodeColor
        }
      }

    return (
        (dagStatus === 'loading') ? <Spinner animation="border" role="status" variant="dagable">
        <span className="visually-hidden">Loading...</span>
      </Spinner> :
        <Graph
            key={version}
            graph={dag}
            events={events}
            options={graphSettings}
        />
    );
}

export default DagableGraph;
